import { CircularProgress, Divider, Stack, useTheme } from '@mui/material';
import { groupBy, sortBy } from 'lodash';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { Text, VSpacer } from '@/components/DesignSystem';
import React from 'react';
import { ResolvedOrderTransaction } from '@/pages/CustomerDashboard/interfaces';
import { RewardsActivityCard } from '@/pages/CustomerDashboard/RewardsActivity/RewardsActivityCard';
import { ApiAccountGroupCustomer, ApiUser } from '@api/interfaces';
import { Resolved } from '@shared/utilities/UtilityTypes';

interface RewardsActivityModalProps {
  farmer: ApiUser,
  linkedAccounts: Resolved<ApiAccountGroupCustomer, 'customer'>[],
  onClose: () => void,
  orders?: ResolvedOrderTransaction[],
}

export const RewardsActivityModal = ({
  farmer,
  linkedAccounts,
  onClose,
  orders,
}: RewardsActivityModalProps) => {
  const theme = useTheme();

  const ordersByMonth = orders
    ? groupBy(orders, (order) => (
      [order.orderDate.getFullYear(), order.orderDate.getMonth()]
    ))
    : undefined;

  return (
    <DashboardModal
      onClose={onClose}
      open
      testID="rewards-activity-modal"
    >
      <Stack flexGrow={1} overflow="auto" px="16px">
        <Stack alignItems="center">
          <Text category="title-large">
            Rewards Activity
          </Text>
        </Stack>
        <VSpacer size="5"/>
        <Text category="body-xsmall" sx={{ color: theme.palette.grey[800] }}>
          Disclaimer: Loyalty points are not earned on miscellaneous fees, taxes, surcharges, etc.
        </Text>
        <VSpacer size="5"/>
        {!ordersByMonth ? (
          <Stack alignItems="center">
            <VSpacer size="14"/>
            <CircularProgress/>
          </Stack>
        ) : (
          <Stack gap="28px">
            {Object.entries(ordersByMonth).map(([key, monthOrders]) => (
              <MonthActivity
                farmer={farmer}
                key={key}
                linkedAccounts={linkedAccounts}
                month={Number(key.split(',')[1])}
                orders={monthOrders}
                year={Number(key.split(',')[0])}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </DashboardModal>
  );
};

interface MonthActivityProps {
  farmer: ApiUser,
  linkedAccounts: Resolved<ApiAccountGroupCustomer, 'customer'>[],
  month: number,
  orders: ResolvedOrderTransaction[],
  year: number,
}

const MonthActivity = ({
  farmer,
  linkedAccounts,
  month,
  orders,
  year,
}: MonthActivityProps) => {
  const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });
  const sortedOrders = sortBy(orders, ({ orderDate }) => -1 * orderDate.getTime());

  return (
    <Stack>
      <Text category="headline-small">
        {monthName} {year}
      </Text>
      <VSpacer size="3"/>
      <Divider light/>
      <Stack divider={<Divider light/>}>
        {sortedOrders.map((order) => {
          let customer: ApiUser | undefined = undefined;
          if (linkedAccounts.length > 0) {
            customer = linkedAccounts.find((account) => (
              account.customerId === order.farmerId
            ))?.customer;
            if (!customer) {
              customer = farmer;
            }
          }
          return (
            <RewardsActivityCard
              customer={customer}
              key={order.id}
              order={order}
            />
          );
        })}
      </Stack>
      <Divider light/>
    </Stack>
  );
};