import { Button, HSpacer, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Stack, useTheme } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';

interface FileUploadProps {
  acceptedFiles: string,
  buttonText?: string,
  buttonIcon?: React.ReactNode,
  imagePath?: string | null,
  onChangeFile: (file: File | undefined) => void,
  viewMode?: boolean,
  width?: number,
}
export const FileUpload = ({
  acceptedFiles,
  buttonText = 'Upload',
  buttonIcon = <UploadIcon />,
  imagePath,
  onChangeFile,
  viewMode = false,
  width,
}: FileUploadProps) => {
  const [uploadedFile, setUploadedFile] = useState<File | null | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const imageUrl = useMemo(() => {
    if (uploadedFile !== undefined) {
      if (uploadedFile?.type.includes('image')) {
        return uploadedFile && URL.createObjectURL(uploadedFile as Blob);
      }
      return null;
    } else {
      return imagePath && `${AppConfig.staticImageHost}/${imagePath}`;
    }
  }, [uploadedFile, imagePath]);

  const FileDisplay = (
    <Stack alignItems="center" p="8px">
      {imageUrl && (
        <img
          src={imageUrl}
        />
      )}
      <VSpacer size="3"/>
      <Stack direction="row" justifyContent="space-between">
        {!!uploadedFile?.name && (
          <Text>{uploadedFile.name}</Text>
        )}
        {!viewMode && (
          <>
            <HSpacer size="4" />
            <TextLink
              category="label-medium"
              color="primary"
              onClick={() => {
                onChangeFile(undefined);
                setUploadedFile(null);
              }}
              testID="remove-textlink"
            >
              Remove
            </TextLink>
          </>
        )}
      </Stack>
    </Stack>
  );

  return (
    <Box
      alignContent="center"
      border={`1px dashed ${theme.palette.divider}`}
      minHeight={88}
      textAlign="center"
      width={width}
    >
      {uploadedFile || imagePath ? (
        FileDisplay
      ) : (
        <Button
          color="inherit"
          disabled={viewMode}
          onClick={() => (inputRef.current as HTMLInputElement)?.click()}
          startIcon={buttonIcon}
          testID='upload-campaign-image-button'
          variant='outlined'
        >
          {buttonText}
          <input
            accept={acceptedFiles}
            id='raised-button-file'
            onChange={(event) => {
              const file = event.target.files?.[0];
              setUploadedFile(file);
              onChangeFile(file);
            }}
            ref={inputRef}
            style={{ display: 'none' }}
            type='file'
          />
        </Button>
      )}
    </Box>
  );
};
