import { Modal, ModalProps } from '@/components/DesignSystem/Modal/Modal';
import { Stack, useTheme } from '@mui/material';
import { Button } from '@/components/DesignSystem';
import Close from '@mui/icons-material/Close';
import React from 'react';

interface DashboardModalProps extends ModalProps {
  hideCloseButtonIcon?: boolean;
}

export const DashboardModal = ({
  hideCloseButtonIcon = false,
  ...props
}: DashboardModalProps) => {
  const theme = useTheme();
  return (
    <Modal
      containerStyleOverride={{ bgcolor: 'white', height: '100%', p: '0px' }}
      hideOnlyHeaderOnMobile
      {...props}
    >
      <Stack color="black" height="100vh" pb="72px">
        {props.children}
      </Stack>
      <Stack
        alignItems="center"
        bgcolor="white"
        bottom="0"
        justifyContent="center"
        minHeight="72px"
        position="fixed"
        sx={{ boxShadow: '0px 2px 16px 0px #0E1E2552' }}
        width="100%"
      >
        <Button
          onClick={props.onClose}
          startIcon={hideCloseButtonIcon ? undefined : <Close />}
          sx={{
            bgcolor: theme.palette.grey[100],
            boxShadow: 'none',
            width: '102px',
          }}
          testID={`${props.testID}-close-button`}
        >
          Close
        </Button>
      </Stack>
    </Modal>
  );
};