import { Size } from '@/themes/variant-interfaces/Size';

import blackFull from '@/assets/logos/black/app_full_black.svg';
import blackSymbol from '@/assets/logos/black/app_symbol_black.svg';
import blackType from '@/assets/logos/black/app_type_black.svg';

import reverseFull from '@/assets/logos/reverse/growers_app_full_reverse.svg';
import reverseHalf from '@/assets/logos/reverse/growers_app_half_reverse.svg';
import reverseLogo from '@/assets/logos/reverse/growers_logo_reverse.svg';
import reverseSymbol from '@/assets/logos/reverse/app_symbol_reverse.svg';
import reverseType from '@/assets/logos/reverse/app_type_reverse.svg';

import whiteSymbol from '@/assets/logos/black/app_white_symbol_black.svg';

import productReverse from '@/assets/logos/reverse/custom_product_reverse.svg';
import productBlack from '@/assets/logos/black/custom_product_black.svg';

import landusIcon from '@/assets/logos/landus/landus_icon.svg';
import landusLogo from '@/assets/logos/landus/landus_logo.svg';
import landusPoweredBy from '@/assets/logos/landus/landus_poweredby_growers.svg';

import growersPoweredBy from '@/assets/logos/black/powered_by_growers.svg';

import { LogoCategory } from '@/themes/variant-interfaces/LogoCategory';
import { getLogo } from '@/utilities/StorefrontUtilities';

export interface LogoProps {
  category?: LogoCategory,
  isMobile?: boolean,
  size?: Size | 'menu',
  subdomain?: string | null,
  onClick?: () => void,
}

const logoMap = {
  'black-full': blackFull,
  'black-symbol': blackSymbol,
  'black-type': blackType,
  'custom-product-black': productBlack,
  'custom-product-reverse': productReverse,
  'growers-logo-reverse': reverseLogo,
  'landus-icon': landusIcon,
  'landus-logo': landusLogo,
  'landus-powered-by': landusPoweredBy,
  'growers-powered-by': growersPoweredBy,
  'reverse-full': reverseFull,
  'reverse-half': reverseHalf,
  'reverse-symbol': reverseSymbol,
  'reverse-type': reverseType,
  'white-symbol': whiteSymbol,
} as const;

const getSize = (size?: Size | 'menu') => {
  switch (size) {
    case 'menu':
      return { height: 20, width: 20 };
    case 'tiny':
      return { height: 32 };
    case 'small':
      return { padding: 10, height: 37.5, width: 100 };
    case 'medium':
      return { width: 109.37, height: 24 };
    case 'large':
      return { height: 96 };
    case 'giant':
      return { height: 80 };
    default:
      return { width: 240, height: 52 };
  }
};

export const Logo = ({
  category = "reverse-full",
  onClick,
  size,
  subdomain,
}: LogoProps)  => {
  if (subdomain) {
    category = getLogo(subdomain);
  }
  return (
    <img
      alt="logo"
      data-testid={category}
      onClick={onClick}
      src={logoMap[category]}
      style={{ cursor: onClick ? "pointer" : undefined, ...getSize(size) }} />
  );
};
