import { Client } from '@/utilities/api/Client';
import { DashboardEndpoint } from '@api/endpoints/DashboardEndpoint';

export class DashboardApi {
  static getData (
    accessToken: string,
  ): Promise<DashboardEndpoint.GetData.Response> {
    return Client(`dashboard/${accessToken}`);
  }

  static listAllOrders (
    accessToken: string,
  ): Promise<DashboardEndpoint.ListAllOrders.Response> {
    return Client(`dashboard/${accessToken}/orders`);
  }

  static listRelevantSalespersons (
    accessToken: string,
  ): Promise<DashboardEndpoint.ListRelevantSalespersons.Response> {
    return Client(`dashboard/${accessToken}/salespersons`);
  }
}
