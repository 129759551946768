import {
  Button,
  Card,
  Dialog,
  HSpacer,
  IconButton,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import {
  Avatar,
  Box,
  ButtonBase,
  CircularProgress,
  Container,
  Divider,
  LinearProgress,
  Stack,
  useTheme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ChevronRight, Info, Remove } from '@mui/icons-material';
import { AppConfig } from '@/constants/AppConfig';
import {
  formatAmount,
  formatDate,
  getProgramInfo,
  getRemainingPctToHurdle,
  useDashboardNavigate,
} from './helpers';
import {
  formatCurrency,
  formatPhoneNumber,
  getJsDateFromString,
  removeWhiteSpace,
} from '@shared/utilities';
import { GrowersContactInfo } from '@shared/enums';
import Check from '@mui/icons-material/Check';
import { ApiRetailer, ApiUser } from '@api/interfaces';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { TierImage } from '@/pages/CustomerDashboard/TierImage';
import { TextBadge } from '@/pages/CustomerDashboard/TextBadge';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { sortBy } from 'lodash';
import { useDashboard } from '@/hooks/useDashboard';
import WavingHandIcon from '@/assets/icons/waving_hand.png';
import TrophyIcon from '@/assets/icons/trophy_icon.svg';
import PoweredByGrowersLogo from '@/assets/logos/black/powered_by_growers.svg';
import PartyPopperIcon from '@/assets/icons/party_popper.png';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { TierProgressBar } from '@/pages/CustomerDashboard/TierProgressBar';
import { TierBenefitsModal } from '@/pages/CustomerDashboard/Benefits/TierBenefitsModal';
import { PromotionDetailsModal } from '@/pages/CustomerDashboard/Promotions/PromotionDetailsModal';
import { PromotionCard } from '@/pages/CustomerDashboard/Promotions/PromotionCard';
import {
  RewardsActivityModal,
} from '@/pages/CustomerDashboard/RewardsActivity/RewardsActivityModal';
import { RewardsActivityCard } from '@/pages/CustomerDashboard/RewardsActivity/RewardsActivityCard';
import { TotalSpendProgressBar } from '@/pages/CustomerDashboard/TotalSpendProgressBar';
import { DashboardApi } from '@/utilities/api/DashboardApi';
import {
  DashboardData,
  ResolvedOrderTransaction,
  ResolvedPromotion,
} from '@/pages/CustomerDashboard/interfaces';
import { useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ResolvedRewardsProgram } from '@api/data/RewardsProgramData';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { Modify } from '@shared/utilities/UtilityTypes';
import { LinkedAccountsModal } from '@/pages/CustomerDashboard/LinkedAccountsModal';
import { getAccountName } from '@shared/utilities/UserUtilities';
import { RewardsUom } from '@shared/enums/RewardsUom';

const cardStyle = {
  bgcolor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);',
  color: 'black',
  padding: '0px',
};
const cardContentStyle = {
  p: "16px",
  "&:last-child": {
    pb: "16px",
  },
};
const darkenedSectionStyle = {
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), #FFF',
};
export const buttonHoverStyle = {
  '&:hover': { bgcolor: 'transparent', borderColor: 'inherit' },
};

export const defaultDashboardData: DashboardData = {
  categories: [],
  farmer: {} as ApiUser,
  linkedAccounts: [],
  orders: [],
  program: {} as ResolvedRewardsProgram,
  promotions: [],
  retailer: {} as Modify<ApiRetailer, { brandColor: string }>,
  rewards: {} as ApiFarmerRewards,
  wallets: [],
};

export const CustomerDashboard = () => {
  const theme = useTheme();
  const { openSnackbar } = useSnackbar();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;
  const navigate = useNavigate();
  const navigateToPage = useDashboardNavigate();
  const [showAllCategoryProgress, setShowAllCategoryProgress] = useState(false);
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [showRewardsActivityModal, setShowRewardsActivityModal] = useState(false);
  const [showPromotionDetailsModal, setShowPromotionDetailsModal] = useState(false);
  const [showTierBenefitsModal, setShowTierBenefitsModal] = useState(false);
  const [showLinkedAccountsModal, setShowLinkedAccountsModal] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState<ResolvedPromotion | null>(null);

  const progressBarStyle = {
    bgcolor: theme.palette.grey[400],
    '.MuiLinearProgress-bar1Determinate': {
      bgcolor: theme.palette.grey[900],
      borderRadius: '8px',
    },
    borderRadius: '8px',
  };

  const handleOnError = () => {
    navigate(Routes.HOMEPAGE);
    openSnackbar('Could not retrieve dashboard');
  };

  const { data: dashboardData } = useDashboard(accessToken);

  const { data: allOrders } = useQuery<ResolvedOrderTransaction[]>(
    [QueryKeys.GET_DASHBOARD_ORDERS, accessToken],
    () => DashboardApi.listAllOrders(accessToken!) as Promise<ResolvedOrderTransaction[]>,
    {
      enabled: !!accessToken && showRewardsActivityModal,
      onError: handleOnError,
    },
  );

  const {
    categories,
    farmer,
    linkedAccounts,
    orders,
    rewards,
    program,
    promotions,
    retailer,
    wallets,
  } = dashboardData ?? defaultDashboardData;

  const {
    areAllCategoriesHurdled,
    currentTier,
    isPreciousMetals,
    isReachedFinalTier,
    isTierSpendHurdled,
    nextTier,
    numCategoriesToHurdle,
    relevantTier,
    spendToHurdle,
  } = useMemo(() => (
    getProgramInfo(program, rewards, !dashboardData)
  ), [dashboardData, program, rewards]);

  const progressToNextTierBannerText = useMemo(() => {
    if (!dashboardData) {
      return '';
    }
    const pluralizedCategory = numCategoriesToHurdle > 1 ? 'categories' : 'category';
    const formattedSpendToHurdle = formatAmount(spendToHurdle);
    if (numCategoriesToHurdle && spendToHurdle) {
      return removeWhiteSpace(`
        ${formattedSpendToHurdle} and ${numCategoriesToHurdle} ${pluralizedCategory}
        remaining to reach next tier
      `);
    } else if (numCategoriesToHurdle) {
      return `${numCategoriesToHurdle} ${pluralizedCategory} remaining to reach next tier`;
    } else if (spendToHurdle) {
      return `${formattedSpendToHurdle} remaining to reach next tier`;
    } else {
      return 'You did it! Final tier complete.';
    }
  }, [dashboardData, numCategoriesToHurdle, spendToHurdle]);

  const howToReachNextTierText = useMemo(() => {
    if (!dashboardData) {
      return '';
    }
    const pluralizedCategory = relevantTier.minimumSegments > 1 ? 'categories' : 'category';
    const formattedSpend = formatAmount(relevantTier.minimumDollars);
    if (relevantTier.minimumSegments && relevantTier.minimumDollars) {
      return removeWhiteSpace(`
        Spend ${formattedSpend} and hit goal in
        ${relevantTier.minimumSegments} ${pluralizedCategory}
      `);
    } else if (relevantTier.minimumSegments) {
      return `Hit goal in ${relevantTier.minimumSegments} ${pluralizedCategory}`;
    } else {
      return `Spend ${formattedSpend}`;
    }
  }, [dashboardData, relevantTier]);

  const programStartJsDate = program.startDate
    ? getJsDateFromString(program.startDate)
    : undefined;
  const programEndJsDate = program.endDate
    ? getJsDateFromString(program.endDate)
    : undefined;
  const programDateRange = (programStartJsDate && programEndJsDate)
    ? `${formatDate(programStartJsDate)} to ${formatDate(programEndJsDate)}`
    : '';

  const campaignImageUrl = program.campaignImage && (
    `${AppConfig.staticImageHost}/${program.campaignImage}`
  );

  const pointsBalance = useMemo(() => {
    if (!wallets || !dashboardData) {
      return 0;
    }
    return wallets.reduce((acc, wallet) => acc + wallet.balance, 0);
  }, [dashboardData, wallets]);

  const orderedSegments = useMemo(() => {
    if (!dashboardData) {
      return [];
    }
    return relevantTier.segments.sort((a, b) => {
      const aRemaining = getRemainingPctToHurdle(a, rewards.totalPerSegment, relevantTier);
      const bRemaining = getRemainingPctToHurdle(b, rewards.totalPerSegment, relevantTier);
      if (aRemaining <= 0 && bRemaining <= 0) {
        const aCategory = categories.find((c) => c.id === a.categoryId)!;
        const bCategory = categories.find((c) => c.id === b.categoryId)!;
        return aCategory.name.localeCompare(bCategory.name);
      } else if (aRemaining <= 0 || bRemaining <= 0) {
        return aRemaining <= 0 ? 1 : -1;
      } else {
        return aRemaining - bRemaining;
      }
    });
  }, [categories, dashboardData, relevantTier, rewards]);

  if (!dashboardData) {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  const Header = (
    <Stack bgcolor={retailer.brandColor} color="white" height="140px">
      <Stack alignItems="center" height="64px" justifyContent="center">
        <Text category="label-medium">
          {retailer.name.toUpperCase()}
        </Text>
      </Stack>
      {!!campaignImageUrl && (
        <Stack alignItems="center" height="76px">
          <img
            height="60px"
            src={campaignImageUrl}
            style={{ objectFit: 'contain' }}
            width="360px"
          />
        </Stack>
      )}
    </Stack>
  );

  const ProgramInfo = (
    <Stack direction="row" justifyContent="space-between" p="16px" sx={darkenedSectionStyle}>
      <Stack>
        <Text category="title-medium">
          {program.name}
        </Text>
        <Text category="body-small">
          {programDateRange}
        </Text>
      </Stack>
      <Button
        onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS, accessToken)}
        sx={{ color: retailer.brandColor, ...buttonHoverStyle }}
        testID="dashboard-how-it-works-button"
        variant="text"
      >
        How it works
      </Button>
    </Stack>
  );

  const FarmerGreeting = (
    <Stack direction="row">
      <Text fontSize="18px" fontWeight={400}>
        Hi, {getAccountName(farmer)}
      </Text>
      <HSpacer size="2" />
      <img
        src={WavingHandIcon}
        style={{ height: '20px', transform: 'scaleX(-1)', width: '20px' }}
      />
    </Stack>
  );

  const BalanceCard = (
    <Card
      cardContentStyle={cardContentStyle}
      sx={cardStyle}
      testID="dashboard-balance-card"
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Text category="overline">
            AVAILABLE BALANCE
          </Text>
          <VSpacer size="1" />
          <Stack direction="row">
            <Text fontSize="24px" fontWeight={500}>
              {formatCurrency(pointsBalance)}
            </Text>
            <HSpacer size="3" />
            <IconButton
              onClick={() => setShowBalanceModal(true)}
              sx={{ color: retailer.brandColor, height: '20px', width: '20px' }}
              testID="dashboard-balance-card-info-button"
            >
              <Info />
            </IconButton>
          </Stack>
        </Stack>
        <Button
          onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD_WALLET, accessToken)}
          sx={{
            bgcolor: 'black',
            color: 'white',
            px: '24px',
            '&:hover': { bgcolor: 'black' },
          }}
          testID="dashboard-wallet-button"
          variant="contained"
        >
          Wallet
        </Button>
      </Stack>
    </Card>
  );

  const StatusCard = (
    <Card
      cardContentStyle={cardContentStyle}
      sx={cardStyle}
      testID="dashboard-status-card"
    >
      <Stack direction="row">
        {isPreciousMetals && (
          <>
            <TierImage isPreciousMetals={isPreciousMetals} tier={currentTier} />
            <HSpacer size="3" />
          </>
        )}
        <Stack>
          <Text category="overline">
            CURRENT TIER
          </Text>
          <VSpacer size="3" />
          <Text fontSize="28px" fontWeight={500}>
            {currentTier.name}
          </Text>
        </Stack>
      </Stack>
      <VSpacer size="4" />
      <Button
        fullWidth
        onClick={() => setShowTierBenefitsModal(true)}
        sx={{
          borderColor: '#D9D9D9',
          color: 'black',
          p: '10px 16px',
          ...buttonHoverStyle,
        }}
        testID="dashboard-status-card-benefits-button"
        variant="outlined"
      >
        <Avatar sx={{ bgcolor: 'black', height: '18px', width: '18px' }}>
          <img src={TrophyIcon} style={{ height: '12px', width: '12px' }}/>
        </Avatar>
        <HSpacer size="3" />
        {currentTier.name} Benefits
      </Button>
    </Card>
  );

  const BenefitsCard = (
    <ButtonBase
      onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD_BENEFITS, accessToken)}
      sx={{ width: '100%' }}
    >
      <Card
        cardContentStyle={{
          p: "12px 24px 12px 16px",
          "&:last-child": {
            pb: "12px",
          },
        }}
        sx={[cardStyle, { width: '100%' }]}
        testID="dashboard-benefits-card"
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            <Avatar sx={{ bgcolor: 'black', height: '40px', width: '40px' }}>
              <img src={TrophyIcon} style={{ height: '24px', width: '24px' }}/>
            </Avatar>
            <HSpacer size="5" />
            <Text category="body-xlarge">
              Program Benefits
            </Text>
          </Stack>
          <ChevronRight sx={{ color: theme.palette.grey[500] }} />
        </Stack>
      </Card>
    </ButtonBase>
  );

  const StatusSection = (
    <Stack p="16px" sx={darkenedSectionStyle}>
      {FarmerGreeting}
      {linkedAccounts.length > 0 && (
        <>
          <VSpacer size="3" />
          <TextLink
            category="label-medium"
            onClick={() => setShowLinkedAccountsModal(true)}
            sx={{ color: '#818B15' }}
            testID="dashboard-linked-accounts-textlink"
          >
            Linked accounts ({linkedAccounts.length})
          </TextLink>
        </>
      )}
      <VSpacer size="5" />
      {StatusCard}
      <VSpacer size="5" />
      {BalanceCard}
      <VSpacer size="5" />
      {BenefitsCard}
      <VSpacer size="4" />
    </Stack>
  );

  const TotalSpendSummary = (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="body-large">
          {relevantTier.minimumSegments ? 'Goal 1: Total spend' : 'Total spend'}
        </Text>
        {isTierSpendHurdled ? (
          <TextBadge text="GOAL COMPLETE" />
        ) : (
          <Stack alignItems="center">
            <Text category="body-medium">
              {formatAmount(relevantTier.minimumDollars - rewards.totalSpend)} remaining
            </Text>
          </Stack>
        )}
      </Stack>
      <VSpacer size="4" />
      {isTierSpendHurdled && (
        <>
          <Stack alignItems="end">
            <Text category="body-small">
              Current total: {formatAmount(rewards.totalSpend)}
            </Text>
          </Stack>
          <VSpacer size="2" />
        </>
      )}
      <Stack px={isTierSpendHurdled ? '0px' : '20px'}>
        <TotalSpendProgressBar
          minimumDollars={relevantTier.minimumDollars}
          totalSpend={rewards.totalSpend}
        />
        <VSpacer size="2" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="body-small">
            $0
          </Text>
          <Text category="body-small">
            {formatAmount(relevantTier.minimumDollars)}
          </Text>
        </Stack>
      </Stack>
    </>
  );

  const numSegments = relevantTier.minimumSegments;
  let chipWidth: string;
  if (numSegments <= 2) {
    chipWidth = '164px';
  } else if (numSegments === 4) {
    chipWidth = '77px';
  } else {
    chipWidth = '106px';
  }
  const hurdled = new Array(relevantTier.minimumSegments - numCategoriesToHurdle).fill(true);
  const notHurdled = new Array(numCategoriesToHurdle).fill(false);
  const CategoriesHurdledChips = (
    <Stack direction="row" flexWrap="wrap" gap="16px" justifyContent="center">
      {[...hurdled, ...notHurdled].map((isHurdled, i) => (
        <Stack
          alignItems="center"
          bgcolor={isHurdled ? '#237804' : theme.palette.grey[300]}
          borderRadius="4px"
          flexGrow={1}
          justifyContent="center"
          key={i}
          maxWidth={chipWidth}
          minWidth="77px"
          p="6px"
          width={chipWidth}
        >
          <Check sx={{ color: 'white' }}/>
        </Stack>
      ))}
    </Stack>
  );

  const CategoriesHurdledSummary = (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="body-large">
          {relevantTier.minimumDollars ? 'Goal 2: Categories completed' : 'Categories completed'}
        </Text>
        {areAllCategoriesHurdled && (
          <>
            <HSpacer size="3" />
            <TextBadge text="GOAL COMPLETE" />
          </>
        )}
      </Stack>
      <VSpacer size="5" />
      {CategoriesHurdledChips}
      {!!numCategoriesToHurdle && (
        <>
          <VSpacer size="4" />
          <Stack alignItems="center">
            <Text category="label">
              {numCategoriesToHurdle} {numCategoriesToHurdle === 1 ? 'category' : 'categories'}
              {' '}
              remaining
            </Text>
          </Stack>
        </>
      )}
    </>
  );

  const ProgressSummary = (
    <Stack>
      {!!relevantTier.minimumDollars && TotalSpendSummary}
      {(!!relevantTier.minimumDollars && !!relevantTier.minimumSegments) && (
        <VSpacer size="6" />
      )}
      {!!relevantTier.minimumSegments && CategoriesHurdledSummary}
    </Stack>
  );

  const ProgressByCategory = (
    <>
      <Stack bgcolor={theme.palette.grey[200]} borderRadius="8px" px="8px" py="16px">
        <Stack px="8px">
          <Text category="overline">
            PROGRESS BY CATEGORY
          </Text>
        </Stack>
        <VSpacer size="7" />
        <Stack gap="16px">
          {orderedSegments.slice(0, showAllCategoryProgress ? undefined : 2).map((segment) => {
            const total = rewards.totalPerSegment[segment.categoryId].total;
            const category = categories.find((c) => c.id === segment.categoryId)!;
            const segmentToHurdle = relevantTier.segments.find(({ categoryId }) => (
              categoryId === segment.categoryId
            ))!;
            const remainingToHurdle = segmentToHurdle.minimumHurdle - total;
            const isHurdleMet = remainingToHurdle <= 0;
            const { uom } = segmentToHurdle;
            const isDollar = uom === RewardsUom.Dollars;
            const statusText = isHurdleMet
              ? `Current total: ${formatAmount(total, isDollar, uom)}`
              : `${formatAmount(remainingToHurdle, isDollar, uom)} remaining`;
            const goalText = `Goal: ${formatAmount(segmentToHurdle.minimumHurdle, isDollar, uom)}`;
            const barProgress = Math.min(total / segmentToHurdle.minimumHurdle * 100, 100);
            return (
              <Stack
                bgcolor={isHurdleMet ? '#389E0D1F' : 'inherit'}
                key={segment.id}
                px="8px"
                py={isHurdleMet ? '16px' : '0px'}
              >
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <Stack alignItems="center" direction="row">
                    <Text fontSize="16px" fontWeight={500}>
                      {category.name}
                    </Text>
                    {isHurdleMet && (
                      <>
                        <HSpacer size="2" />
                        <CheckCircle sx={{ color: '#237804', height: '18px', width: '18px' }} />
                      </>
                    )}
                  </Stack>
                  <Text category="body-medium">
                    {statusText}
                  </Text>
                </Stack>
                <VSpacer size="2" />
                <VSpacer size="1" />
                <LinearProgress
                  sx={[progressBarStyle, { height: '6px' }]}
                  value={barProgress}
                  variant="determinate"
                />
                <VSpacer size="2"/>
                <Stack alignItems="end">
                  <Text category="body-small">
                    {goalText}
                  </Text>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      {relevantTier.segments.length > 2 && (
        <>
          <VSpacer size="6" />
          <Stack alignItems="center">
            <Button
              onClick={() => setShowAllCategoryProgress(!showAllCategoryProgress)}
              startIcon={showAllCategoryProgress ? <Remove /> : undefined}
              sx={{
                borderColor: '#D9D9D9',
                color: 'black',
                p: '10px 16px',
                ...buttonHoverStyle,
              }}
              testID="dashboard-show-all-categories-button"
              variant="outlined"
            >
              {showAllCategoryProgress
                ? 'Show less'
                : `Show all ${relevantTier.segments.length} categories`}
            </Button>
          </Stack>
        </>
      )}
    </>
  );

  const nextYear = getJsDateFromString(program.endDate).getFullYear() + 1;
  const ProgressSection = (
    <Stack p="24px 16px 16px">
      <Text category="headline-medium">
        My Progress
      </Text>
      <VSpacer size="1" />
      <Text category="body-medium" sx={{ color: theme.palette.grey[800] }}>
        Progress towards {rewards.rankOverride !== null ? 're-' : ''}earning your loyalty status
        for {nextYear} program year
      </Text>
      {rewards.earnedRank !== null && (
        <>
          <VSpacer size="5" />
          <Stack alignItems="center">
            <TierProgressBar
              currentRank={rewards.earnedRank}
              tierNames={program.tiers.map(({ name }) => name)}
            />
          </Stack>
          <VSpacer size="5" />
          <Stack alignItems="center">
            <Stack
              alignItems="center"
              bgcolor={isReachedFinalTier ? undefined : '#389E0D1F'}
              direction="row"
              maxWidth="fit-content"
              p="4px 12px"
            >
              {isReachedFinalTier && (
                <>
                  <img src={PartyPopperIcon} style={{ height: '18px', width: '18px' }}/>
                  <HSpacer size="3"/>
                </>
              )}
              <Text category={isReachedFinalTier ? 'label-large' : 'body-small'}>
                {progressToNextTierBannerText}
              </Text>
            </Stack>
          </Stack>
        </>
      )}
      <VSpacer size="5" />
      <Divider light />
      <VSpacer size="5" />
      <Text category="overline">
        TO {isReachedFinalTier ? `MAINTAIN ${currentTier.name}` : `REACH ${nextTier!.name}`}
      </Text>
      <VSpacer size="2" />
      <Text category="body-large">
        {howToReachNextTierText}
      </Text>
      <VSpacer size="4" />
      <Divider light />
      <VSpacer size="5" />
      {ProgressSummary}
      {relevantTier.minimumSegments > 0 && (
        <>
          <VSpacer size="8" />
          {ProgressByCategory}
        </>
      )}
    </Stack>
  );

  const RewardsActivitySection = (
    <Stack p="16px">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <Text category="headline-medium">
            Rewards Activity
          </Text>
          <VSpacer size="1" />
          <Text category="body-medium" sx={{ color: theme.palette.grey[800] }}>
            Points earned & redeemed
          </Text>
        </Stack>
        <TextLink
          accessoryRight={<KeyboardArrowRight />}
          onClick={() => setShowRewardsActivityModal(true)}
          sx={{ color: retailer.brandColor }}
          testID="dashboard-rewards-activity-textlink"
        >
          View all
        </TextLink>
      </Stack>
      <VSpacer size="5" />
      <Divider light />
      <Stack divider={<Divider light />}>
        {sortBy(orders, ({ orderDate }) => -1 * orderDate.getTime()).slice(0, 3).map((order) => {
          let customer: ApiUser | undefined = undefined;
          if (linkedAccounts.length > 0) {
            customer = linkedAccounts.find((account) => (
              account.customerId === order.farmerId
            ))?.customer;
            if (!customer) {
              customer = farmer;
            }
          }
          return (
            <RewardsActivityCard customer={customer} key={order.id} order={order} />
          );
        })}
      </Stack>
      <Divider light />
    </Stack>
  );

  const PromotionSection = (
    <Stack>
      <Stack p="16px">
        <Text category="headline-medium">
          Product Promotions
        </Text>
        <VSpacer size="1" />
        <Text category="body-medium" sx={{ color: theme.palette.grey[800] }}>
          Exclusive offers for our loyal customers
        </Text>
      </Stack>
      <VSpacer size="5" />
      <Box bgcolor={retailer.brandColor} height="302px" overflow="auto" px="16px" py="24px">
        <Stack direction="row" height="100%">
          <Stack direction="row" gap="16px" height="100%">
            {promotions.map((promotion) => (
              <PromotionCard
                key={promotion.id}
                onClickDetails={() => {
                  setSelectedPromotion(promotion);
                  setShowPromotionDetailsModal(true);
                }}
                promotion={promotion}
              />
            ))}
          </Stack>
          <Box height="100%" minWidth="16px" />
        </Stack>
      </Box>
    </Stack>
  );

  const Disclaimer = (
    <Stack alignItems="center" px="16px">
      <Text category="body-xsmall" sx={{ color: theme.palette.grey[700] }}>
        <span style={{ fontWeight: 500 }}>Disclaimer: </span>
        Loyalty points are not earned on miscellaneous fees, taxes, surcharges, etc.
      </Text>
    </Stack>
  );

  const Footer = (
    <Stack alignItems="center" p="32px 16px 24px" sx={darkenedSectionStyle}>
      <img src={PoweredByGrowersLogo} style={{ height: '42px' }} />
      <VSpacer size="7" />
      <Text category="title-small">
        Need help? Contact GROWERS support.
      </Text>
      <Stack direction="row">
        <Button
          href={`tel:${GrowersContactInfo.supportPhone}`}
          sx={{ color: retailer.brandColor, ...buttonHoverStyle }}
          testID="dashboard-contact-telephone-button"
          variant="text"
        >
          <Phone sx={{ height: '18px', width: '18px' }} />
          <HSpacer size="2" />
          {formatPhoneNumber(GrowersContactInfo.supportPhone, 'paren')}
        </Button>
        <Button
          href={`mailto:${GrowersContactInfo.supportEmail}`}
          sx={{ color: retailer.brandColor, ...buttonHoverStyle }}
          testID="dashboard-contact-email-button"
          variant="text"
        >
          <Email sx={{ height: '18px', width: '18px' }} />
          <HSpacer size="2" />
          {GrowersContactInfo.supportEmail}
        </Button>
      </Stack>
      <VSpacer size="7" />
      <TextLink
        category="body-small"
        href={GrowersContactInfo.termsOfUseLink}
        rel="noopener noreferrer"
        sx={{ color: '#525252', textDecoration: 'underline' }}
        target="_blank"
        testID="dashboard-terms-and-conditions-link"
      >
        Terms & Conditions
      </TextLink>
    </Stack>
  );

  const BalanceModal = (
    <Dialog
      cancelButton={(props) => (
        <Button
          onClick={() => setShowBalanceModal(false)}
          sx={{
            border: `1px solid ${retailer.brandColor}`,
            color: retailer.brandColor,
            p: '10px 16px',
          }}
          variant="outlined"
          {...props}
        >
          Close
        </Button>
      )}
      onClose={() => setShowBalanceModal(false)}
      open
      showCloseButton={false}
      sx={{ bgcolor: 'white', color: 'black' }}
      testID="dashboard-balance-modal"
    >
      <Stack alignItems="center">
        <InfoOutlined sx={{ color: retailer.brandColor }} />
        <VSpacer size="5" />
        <VSpacer size="1" />
        <Text category="headline-small">
          Balance
        </Text>
        <VSpacer size="5" />
        <Text category="body-medium">
          Your balance is the total amount of money available to be applied towards
          new qualifying orders.
        </Text>
      </Stack>
    </Dialog>
  );

  return  (
    <>
      <Stack
        bgcolor="white"
        color="black"
        maxWidth="599px"
        minHeight="100vh"
        minWidth="360px"
      >
        {Header}
        {ProgramInfo}
        <Divider light />
        {StatusSection}
        <Divider light />
        {ProgressSection}
        <VSpacer size="8" />
        {RewardsActivitySection}
        {!!promotions.length && (
          <>
            <VSpacer size="8" />
            {PromotionSection}
          </>
        )}
        <VSpacer size="8" />
        {Disclaimer}
        <VSpacer size="7" />
        <Divider light />
        {Footer}
      </Stack>
      {showBalanceModal && BalanceModal}
      {showRewardsActivityModal && (
        <RewardsActivityModal
          farmer={farmer}
          linkedAccounts={linkedAccounts}
          onClose={() => setShowRewardsActivityModal(false)}
          orders={allOrders}
        />
      )}
      {showPromotionDetailsModal && !!selectedPromotion && (
        <PromotionDetailsModal
          onClose={() => {
            setShowPromotionDetailsModal(false);
            setSelectedPromotion(null);
          }}
          promotion={selectedPromotion}
          retailerName={retailer.name}
        />
      )}
      {showTierBenefitsModal && (
        <TierBenefitsModal
          brandColor={retailer.brandColor}
          categories={categories}
          isPreciousMetals={isPreciousMetals}
          onClose={() => setShowTierBenefitsModal(false)}
          tier={currentTier}
        />
      )}
      {showLinkedAccountsModal && linkedAccounts.length > 0 && (
        <LinkedAccountsModal
          accounts={linkedAccounts}
          farmer={farmer}
          onClose={() => setShowLinkedAccountsModal(false)}
        />
      )}
    </>
  );
};
