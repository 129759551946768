import { ApiPromotion } from '@api/interfaces';
import { Stack, useTheme } from '@mui/material';
import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import Star from '@mui/icons-material/Star';
import { formatDate } from '@/pages/CustomerDashboard/helpers';
import { getJsDateFromString } from '@shared/utilities';
import React from 'react';

interface PromotionCardProps {
  onClickDetails: () => void,
  promotion: ApiPromotion,
}

export const PromotionCard = ({
  onClickDetails,
  promotion,
}: PromotionCardProps) => {
  const theme = useTheme();
  return (
    <Stack
      bgcolor="white"
      justifyContent="space-between"
      minWidth="252px"
      p="16px"
      width="252px"
    >
      <Text fontSize="24px" fontWeight={500} sx={{ hyphens: 'auto', wordBreak: 'break-word' }}>
        {promotion.name}
      </Text>
      <VSpacer size="3"/>
      <Stack alignItems="center" direction="row">
        <Star sx={{ color: '#F1BC42', height: '20px', width: '20px' }}/>
        <HSpacer size="2"/>
        <Text align="center" fontSize="14px" fontWeight={400}>
          {(promotion.value * 100).toFixed(3)}% points earned
        </Text>
      </Stack>
      <VSpacer size="8"/>
      <Stack alignItems="center">
        <Button
          onClick={onClickDetails}
          sx={{
            bgcolor: theme.palette.grey[100],
            boxShadow: 'none',
            '&:hover': { bgcolor: theme.palette.grey[100] },
          }}
          testID={`dashboard-promotion-${promotion.id}-button`}
        >
          Details
        </Button>
        <VSpacer size="4"/>
        <Text category="body-small">
          Expires {formatDate(getJsDateFromString(promotion.endDate))}
        </Text>
      </Stack>
    </Stack>
  );
};