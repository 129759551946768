import { Divider, Stack } from '@mui/material';
import {
  Button,
  HSpacer,
  IconButton,
  Input,
  Modal,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import AddIcon from '@mui/icons-material/Add';
import { useMemo, useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import ConfirmModal from "@/components/shared/ConfirmModal";

interface WebsiteCardProps {
  onChange: (url: string | null) => void,
  url?: string | null,
}

export const WebsiteCard = ({
  onChange,
  url,
}: WebsiteCardProps) => {
  const [showWebsiteModal, setShowWebsiteModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  return (
    <>
      <Stack>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="body-xlarge">
            Retailer Website
          </Text>
          <Stack alignItems="center" direction="row">
            {url && (
              <>
                <IconButton
                  onClick={() => setShowConfirmDeleteModal(true)}
                  testID="remove-website-button"
                >
                  <DeleteOutline />
                </IconButton>
                <HSpacer size="4" />
              </>
            )}
            <Button
              color={url ? 'inherit' : 'primary'}
              disabled={url === undefined}
              onClick={() => setShowWebsiteModal(true)}
              startIcon={url ? <Edit/> : <AddIcon/>}
              testID="add-website-button"
              variant="outlined"
            >
              {url ? 'Edit' : 'Add Website'}
            </Button>
          </Stack>
        </Stack>
        {!!url && (
          <>
            <VSpacer size="3" />
            <Text category="body-medium">
              URL: {url}
            </Text>
          </>
        )}
        <VSpacer size="6" />
        <Divider />
      </Stack>
      {showWebsiteModal && (
        <WebsiteModal
          onClose={() => setShowWebsiteModal(false)}
          onSave={(newUrl) => onChange(newUrl)}
          url={url}
        />
      )}
      {showConfirmDeleteModal && (
        <ConfirmModal
          confirmButtonText="Yes, delete"
          message="Are you sure you want to delete the retailer website?"
          onCancel={() => setShowConfirmDeleteModal(false)}
          onConfirm={() => {
            onChange(null);
            setShowConfirmDeleteModal(false);
          }}
          open
          testID="confirm-delete-retailer-website-modal"
          title="Are you sure?"
        />
      )}
    </>
  );
};

interface WebsiteModalProps {
  onClose: () => void,
  onSave: (url: string) => void,
  url?: string | null,
}

const WebsiteModal = ({
  onClose,
  onSave,
  url,
}: WebsiteModalProps) => {
  const [value, setValue] = useState(url ?? '');
  const isEditing = !!url;
  const isValueChanged = value !== (url ?? '');
  const properValue = value.includes('://') ? value : `https://${value}`;
  const isValidUrl = useMemo(() => {
    try {
      new URL(properValue);
      return true;
    } catch {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const isValid = isValueChanged && value.length && isValidUrl;

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            onSave(properValue);
          }}
          testID="website-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          testID="website-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID="website-modal"
      title={`${isEditing ? 'Edit' : 'Add'} Retailer Website`}
    >
      <Input
        label="Website URL"
        onChangeText={setValue}
        testID="website-modal-input"
        value={value} />
    </Modal>
  );
};
