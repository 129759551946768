import { ApiCategory } from '@api/interfaces';
import { ResolvedTier } from '@api/data/RewardsProgramData';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { Stack } from '@mui/material';
import { Text, VSpacer } from '@/components/DesignSystem';
import { BenefitCard } from '@/pages/CustomerDashboard/Benefits/BenefitCard';
import React from 'react';

import { AwardPointsEarned } from '@/pages/CustomerDashboard/Benefits/AwardPointsEarned';

interface TierBenefitsModalProps {
  brandColor: string,
  categories: ApiCategory[],
  isPreciousMetals: boolean,
  onClose: () => void,
  tier: ResolvedTier,
}

export const TierBenefitsModal = ({
  brandColor,
  categories,
  isPreciousMetals,
  onClose,
  tier,
}: TierBenefitsModalProps) => (
  <DashboardModal
    onClose={onClose}
    open
    testID="tier-benefits-modal"
  >
    <Stack flexGrow={1} overflow="auto">
      <Stack alignItems="center">
        <Text category="title-large">
          {tier.name} Tier Benefits
        </Text>
      </Stack>
      <VSpacer size="5"/>
      <Stack gap="16px" pb="32px" px="16px">
        {tier.benefits.map((benefit) => (
          <BenefitCard
            benefit={benefit}
            brandColor={brandColor}
            isPreciousMetals={isPreciousMetals}
            key={benefit.id}
            testID={`tier-benefits-modal-benefit-card-${benefit.id}`}
            tierName={tier.name}
          />
        ))}
        <AwardPointsEarned
          categories={categories}
          testID={`tier-benefits-modal-award-points-earned`}
          tier={tier}
        />
      </Stack>
    </Stack>
  </DashboardModal>
);