import GrowersIcon from '@/assets/icons/growers-icon.svg';
import ManufacturerIcon from '@/assets/icons/manufacturer-icon.svg';
import RetailerIcon from '@/assets/icons/retailer-icon.svg';

export interface Wallet {
  points: number,
  manufacturerId: string | null,
  manufacturerName: string | null,
  name: string,
  retailerId: string | null,
  retailerName: string | null,
}

export const getIcon = (retailerId: string | null, manufacturerId: string | null) => {
  if (!manufacturerId && !retailerId) {
    return GrowersIcon;
  } else if (manufacturerId) {
    return ManufacturerIcon;
  }
  return RetailerIcon;
};
