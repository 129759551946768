import { Card, HSpacer, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { Box, Stack } from '@mui/material';
import SketchyCheckIcon from '@/assets/icons/sketchy_check.svg';
import React from 'react';
import { ApiRewardsTierBenefit } from '@api/interfaces/ApiRewardsTierBenefit';

const boxShadow = '0px 54px 55px 0px rgba(0, 0, 0, 0.25), 0px -12px 30px 0px rgba(0, 0, 0, 0.12),' +
  '0px 4px 6px 0px rgba(0, 0, 0, 0.12), 0px 12px 13px 0px rgba(0, 0, 0, 0.17),' +
  '0px -3px 5px 0px rgba(0, 0, 0, 0.09);';
const benefitCardStyle = {
  bgcolor: 'white',
  boxShadow,
  borderRadius: '0px',
  color: 'black',
  padding: '0px',
  position: 'relative',
};
const metallicGradientBackgroundStyles: Record<string, string> = {
  'Bronze': 'linear-gradient(90deg, #765F52 0%, #9B8A79 18.6%,' +
    ' #F0D1B0 40.04%, #987854 60.99%, #C29C76 82.92%, #756054 100%)',
  'Silver': 'linear-gradient(270deg, #7F95AA 0%, #EBEFF2 18.26%, #C5D3DF 39.69%,' +
    ' #FFFFFF 59.92%, #D6DEE4 73.41%, #AEBDC7 85.71%, #BECAD6 100%);',
  'Gold': 'linear-gradient(90deg, #834626 0%, #F8E68A 34.35%, #FCFBE9 56.36%,' +
    ' #F2D166 77.73%, #CDA152 100%);',
  'Platinum': 'linear-gradient(270deg, #B0B0B0 0%, #868686 15.48%, #D1D1D1 40.4%,' +
    ' #FFFFFF 54%, #878787 84.75%, #737373 100%);',
};

interface BenefitCardProps {
  benefit: ApiRewardsTierBenefit,
  brandColor: string,
  isPreciousMetals: boolean,
  testID: string,
  tierName: string,
}

export const BenefitCard = ({
  benefit,
  brandColor,
  isPreciousMetals,
  testID,
  tierName,
}: BenefitCardProps) => (
  <Card
    sx={benefitCardStyle}
    testID={testID}
  >
    <Stack direction="row">
      <img src={SketchyCheckIcon} style={{ height: '20px', width: '20px' }}/>
      <HSpacer size="4"/>
      <Stack>
        <Text align="left" fontSize="18px" fontWeight={400} testID={`${testID}-name`}>
          {benefit.name}
        </Text>
        <Text align="left" category="body-medium" testID={`${testID}-description`}>
          {benefit.description}
        </Text>
        {!!benefit.termsUrl && (
          <>
            <VSpacer size="3"/>
            <TextLink
              category="body-xsmall"
              onClick={() => {
                const url = benefit.termsUrl!.includes('//')
                  ? benefit.termsUrl!
                  : `//${benefit.termsUrl}`;
                window.open(url, '_blank', 'noopener noreferrer');
              }}
              sx={{ color: '#525252', textDecoration: 'underline' }}
              testID={`${testID}-terms-link`}
            >
              View Terms
            </TextLink>
          </>
        )}
      </Stack>
    </Stack>
    <Box
      height="4px"
      sx={{
        background: isPreciousMetals
          ? metallicGradientBackgroundStyles[tierName]
          : brandColor,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    />
  </Card>
);