import { AppHeader } from '@/components/shared/AppHeader';
import { Routes } from '@/constants/Routes';
import { VerifyEmail } from '@/pages/Auth/VerifyEmail';
import { PlaceOfferSuccess } from '@/pages/ReceivePlaceOffers/PlaceOfferSuccess';
import RedirectToOs from '@/pages/RedirectToOs/RedirectToOs';
import { ViewPriceRequest } from '@/pages/ViewPriceRequest/ViewPriceRequest';
import { Route, Routes as AppRoutes } from 'react-router-dom';
import { CustomerDashboard } from '@/pages/CustomerDashboard/CustomerDashboard';
import { BenefitsPage } from '@/pages/CustomerDashboard/BenefitsPage';
import { HowItWorksPage } from '@/pages/CustomerDashboard/HowItWorksPage';
import { WalletPage } from '@/pages/CustomerDashboard/WalletPage';
import React from 'react';

export const PublicView = () => {

  const createLayout = (element: React.ReactNode) => {
    return (
      <>
        <AppHeader />
        {element}
      </>
    );
  };

  return (
    <AppRoutes>
      <Route element={<ViewPriceRequest />} path={Routes.PLACE_OFFER} />
      <Route element={<RedirectToOs />} path={Routes.REDIRECT} />
      <Route element={createLayout(<PlaceOfferSuccess />)} path={Routes.PLACE_OFFER_SUCCESS} />
      <Route element={createLayout(<VerifyEmail />)} path={Routes.VERIFY_EMAIL} />
      <Route element={<CustomerDashboard />} path={Routes.CUSTOMER_DASHBOARD} />
      <Route element={<CustomerDashboard />} path={Routes.CUSTOMER_DASHBOARD_ALT} />
      <Route element={<BenefitsPage />} path={Routes.CUSTOMER_DASHBOARD_BENEFITS} />
      <Route element={<BenefitsPage />} path={Routes.CUSTOMER_DASHBOARD_BENEFITS_ALT} />
      <Route element={<HowItWorksPage />} path={Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS_ALT} />
      <Route element={<HowItWorksPage />} path={Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS} />
      <Route element={<WalletPage />} path={Routes.CUSTOMER_DASHBOARD_WALLET} />
      <Route element={<WalletPage />} path={Routes.CUSTOMER_DASHBOARD_WALLET_ALT} />
    </AppRoutes>
  );
};
