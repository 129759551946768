import { formatPhoneNumber } from './StringUtils';

export const getAccountName = (
  user: {
    firstName?: string | null;
    lastName?: string | null;
    businessName?: string | null;
    telephone?: string | null;
    email?: string | null;
    externalId?: string | null;
  },
) => {
  const options = [
    (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : undefined,
    user.firstName,
    user.lastName,
    user.businessName,
    user.telephone ? formatPhoneNumber(user.telephone, 'paren') : undefined,
    user.email,
    user.externalId,
  ];
  return options.find((option) => !!option) ?? 'Customer';
};