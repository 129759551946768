import { QueryKeys } from '@/constants/QueryKeys';
import { useQuery } from 'react-query';
import { DashboardApi } from '@/utilities/api/DashboardApi';
import { Routes } from '@/constants/Routes';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DashboardData } from '@/pages/CustomerDashboard/interfaces';

export const useDashboard = (accessToken?: string | null) => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const handleOnError = () => {
    navigate(Routes.HOMEPAGE);
    openSnackbar('Could not retrieve dashboard');
  };

  const { data } = useQuery<DashboardData>(
    [QueryKeys.GET_DASHBOARD_DATA, accessToken],
    () => DashboardApi.getData(accessToken!) as Promise<DashboardData>,
    {
      enabled: !!accessToken,
      onError: handleOnError,
      staleTime: 5 * 60 * 1000,
    },
  );

  return {
    data,
  } as { data: DashboardData | undefined };
};
