import { Stack } from '@mui/material';
import { Text } from '@/components/DesignSystem';

interface TextBadgeProps {
  backgroundColor?: string,
  textColor?: string,
  text: string,
}

export const TextBadge = ({
  backgroundColor = '#1890FF',
  textColor = 'white',
  text,
}: TextBadgeProps) => (
  <Stack
    bgcolor={backgroundColor}
    borderRadius="100px"
    color={textColor}
    height="16px"
    justifyContent="center"
    px="6px"
  >
    <Text category="label-small">
      {text}
    </Text>
  </Stack>
);
