import { Client } from '@/utilities/api/Client';
import { RewardsProgramEndpoint } from '@api/endpoints/RewardsProgramEndpoint';
import { RetailerEndpoint } from "@api/endpoints";

export class RewardsProgramApi {
  static create (
    body: RewardsProgramEndpoint.Create.Request,
  ): Promise<RewardsProgramEndpoint.Create.Response> {
    return Client(`rewards-programs`, {
      method: 'POST',
      body,
    });
  }

  static delete (id: string): Promise<void> {
    return Client(`rewards-programs/${id}`, { method: 'DELETE' });
  }

  static getActiveProgramForLocation (
    locationId: string,
  ): Promise<RewardsProgramEndpoint.GetActiveProgramForLocation.Response> {
    return Client(`rewards-programs/active-for-location/${locationId}`);
  }

  static getActiveProgramForRetailer (
    retailerId: string,
  ): Promise<RetailerEndpoint.GetActiveRetailerRewardsProgram.Response> {
    return Client(`retailer/${retailerId}/active-rewards-program`);
  }

  static hasActiveUsers (
    id: string,
  ): Promise<RewardsProgramEndpoint.HasActiveUsers.Response> {
    return Client(`rewards-programs/${id}/has-active-users`);
  }

  static update (
    id: string,
    body: RewardsProgramEndpoint.Update.Request,
  ): Promise<RewardsProgramEndpoint.Update.Response> {
    return Client(`rewards-programs/${id}`, {
      method: 'PATCH',
      body,
    });
  }
}
