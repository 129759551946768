import { ApiCategory } from '@api/interfaces';
import { ResolvedTier } from '@api/data/RewardsProgramData';
import { Divider, Stack } from '@mui/material';
import { Card, Text, VSpacer } from '@/components/DesignSystem';
import { getRewardsValueText } from '@/pages/CustomerDashboard/helpers';
import React from 'react';

const awardPointsEarnedCardStyle = {
  bgcolor: '#135200',
};
const awardPointsEarnedTableStyle = {
  bgcolor: 'white',
  color: 'black',
  padding: '8px 16px',
  "&:last-child": {
    pb: "8px",
  },
};

interface AwardPointsEarnedProps {
  categories: ApiCategory[],
  testID: string,
  tier: ResolvedTier,
}

export const AwardPointsEarned = ({
  categories,
  testID,
  tier,
}: AwardPointsEarnedProps) => {

  const TableContent = (
    <Stack divider={<Divider light/>}>
      {tier.segments.map((segment) => {
        const { categoryId, rewardsType, rewardsValue, uom } = segment;
        const categoryName = categories.find(({ id }) => id === categoryId)!.name;
        if (segment.subcategoryRewards) {
          return (
            <Stack key={categoryId}>
              <Stack
                alignItems="center"
                direction="row"
                height="28px"
                key={categoryId}
                py="4px"
              >
                <Text
                  fontSize="14px"
                  fontWeight={700}
                >
                  {categoryName}
                </Text>
              </Stack>
              <Stack divider={<Divider light/>} pl="16px">
                {Object.entries(segment.subcategoryRewards).map(([subcatId, subcatRewards]) => (
                  <Stack
                    alignItems="center"
                    direction="row"
                    height="28px"
                    justifyContent="space-between"
                    key={subcatId}
                    py="4px"
                  >
                    <Text category="body-medium">
                      {categories.find(({ id }) => id === subcatId)!.name}
                    </Text>
                    <Text category="title-small">
                      {getRewardsValueText(
                        subcatRewards.rewardsType,
                        subcatRewards.rewardsValue,
                        uom,
                      )}
                    </Text>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          );
        } else {
          return (
            <Stack
              alignItems="center"
              direction="row"
              height="28px"
              justifyContent="space-between"
              key={categoryId}
              py="4px"
            >
              <Text category="body-medium">
                {categoryName}
              </Text>
              <Text category="title-small">
                {getRewardsValueText(rewardsType, rewardsValue!, uom)}
              </Text>
            </Stack>
          );
        }
      })}
    </Stack>
  );

  return (
    <Card
      sx={awardPointsEarnedCardStyle}
      testID={testID}
    >
      <Stack alignItems="center">
        <Text category="overline">
          {tier.name} tier status
        </Text>
        <VSpacer size="2"/>
        <Text category="title-large">
          Award Points Earned
        </Text>
        <VSpacer size="4"/>
        <Card
          cardContentStyle={awardPointsEarnedTableStyle}
          sx={{ width: '100%' }}
          testID={`${testID}-table`}
        >
          {TableContent}
        </Card>
      </Stack>
    </Card>
  );
};