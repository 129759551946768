import _ from 'lodash';

export function alphabetizeKeys<T extends object> (object: T): T {
  const result: Partial<T> = {};
  const keys = Object.keys(object);
  const sortedKeys = keys.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  sortedKeys.forEach((key) => {
    let value = object[key as keyof T];
    if (isObject(value)) {
      value = alphabetizeKeys(value) as T[keyof T];
    } else if (Array.isArray(value) && isObject(value[0])) {
      value = value.map(alphabetizeKeys) as T[keyof T];
    }
    result[key as keyof T] = value;
  });

  return result as T;
}

/**
 * WARNING: Use with caution. This modifies the object in place. This is necessary for certain
 * places where copying the object does not work, such as an object with Symbol keys.
 */
export function alphabetizeKeysInPlace<T extends object> (object: T, maxDepth = 5): void {
  if (maxDepth < 0) { return; }
  const keys = Object.keys(object);
  const sortedKeys = keys.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  sortedKeys.forEach((key) => {
    const value = object[key as keyof T];
    if (isObject(value)) {
      alphabetizeKeysInPlace(value, maxDepth - 1);
    }
    delete object[key as keyof T];
    object[key as keyof T] = value;
  });
}

/**
 * Run an async function on each item of an array.
 * @param items The array to iterate over
 * @param operation The operation to run on each item in the array
 */
export async function asyncForEach<Input> (
  items: Set<Input> | null | undefined,
  operation: (item: Input, index: number) => Promise<any>,
): Promise<void>;
export async function asyncForEach<Input> (
  items: Array<Input> | null | undefined,
  operation: (item: Input, index: number) => Promise<any>,
): Promise<void>;
export async function asyncForEach<Input> (
  items: Set<Input> | Array<Input> | null | undefined,
  operation: (item: Input, index: number) => Promise<any>,
): Promise<void> {
  if (!items || _.isEmpty(items)) { return; }
  const list = (items instanceof Set) ? Array.from(items) : items;
  await Promise.all(list.map((value, index) => operation(value, index)));
}

/**
 * Run an async function on each item of an array and return the results.
 * @param items The array to iterate over
 * @param operation The operation to run on each item in the array
 * @returns The result of operation for each item in items.
 */
export function asyncMap<Input, Output> (
  items: Array<Input>,
  operation: (item: Input, index: number) => Promise<Output>,
): Promise<Array<Output>> {
  return Promise.all(items.map((value, index) => operation(value, index)));
}

export async function asyncMapSequential<Input, Output> (
  items: Array<Input>,
  operation: (item: Input, index: number) => Promise<Output>,
): Promise<Array<Output>> {
  const responses = [];
  for (let i = 0; i < items.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    responses.push(await operation(items[i], i));
  }
  return responses;
}

export async function asyncForEachWithConcurrencyLimit<Input> (
  items: Input[],
  operation: (item: Input) => Promise<void>,
  limit: number,
): Promise<void> {
  const executing: Promise<void>[] = [];
  for (const item of items) {
    const p = operation(item).then(() => {
      executing.splice(executing.indexOf(p), 1);
    });
    executing.push(p);
    if (executing.length >= limit) {
      await Promise.race(executing);
    }
  }
  await Promise.all(executing);
}

export function groupBy <Value> (
  objects: Value[],
  evaluator: (v: Value) => string,
): {[key: string]: Value[]} {
  const result: {[key: string]: Value[]} = {};

  for (const item of objects) {
    const key = evaluator(item);
    if (!(key in result)) { result[key] = []; }
    result[key].push(item);
  }

  return result;
}

export function isObject (obj: unknown): obj is object {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function notEmpty<TValue> (value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export type Nullable<T> = T | null;

export function objectKeys<T extends object> (obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

/**
 * Take an object, and run `operation` for each key, and return a new object with the same keys
 * as the input, but with each value set the result of `operation` for that key's value.
 * @param obj
 * @param operation
 */
export function objectMap <Input extends object, Output> (
  obj: Input | null | undefined,
  operation: (value: NonNullable<Input[keyof Input]>, key: keyof Input, index: number) => Output,
) {
  if (obj == null) {
    return;
  }

  return Object.fromEntries(
    Object.entries(obj).map(
      ([key, value], index) => [key, operation(value, key as keyof Input, index)],
    ),
  ) as Record<keyof Input, NonNullable<Output>>;
}

/**
 * Return a shallow copy of input that includes all keys not in the list provided.
 */
export function omit<T extends object, K extends keyof T> (input: T, keys: K[]): Omit<T, K> {
  const result: any = {};
  Object.keys(input).forEach((key) => {
    if (!((keys as string[]).includes(key))) {
      result[key] = (input as any)[key];
    }
  });
  return result;
}

export function pick<T extends object, K extends keyof T> (input: T, keys: K[]): Pick<T, K> {
  const result: { [key in K]?: unknown } = {};
  keys.forEach((key) => {
    result[key] = input[key];
  });
  return result as Pick<T, K>;
}

/**
 * Recursively search through all items in the object, and run operation for each one, replacing
 * the original value with the result of operation. Arrays will be iterated over with operation
 * run on each item.
 */
export function recursiveRewrite <Input, Output> (
  obj: Input,
  operation: (value: unknown, key: string) => Output,
  maxDepth: number = 10,
): Input {
  if (maxDepth === 0) { return obj; }
  maxDepth -= 1;
  if (Array.isArray(obj)) {
    return obj.map((i) => recursiveRewrite(i, operation, maxDepth)) as Input;
  } else if (isObject(obj)) {
    return Object.keys(obj).reduce(
      (accum, key) => {
        const value = (obj as { [key: string]: unknown })[key];
        accum[key] = recursiveRewrite(value, operation, maxDepth);
        return accum;
      },
      {} as { [key: string]: unknown },
    ) as Input;
  } else {
    return operation(obj, '') as unknown as Input;
  }
}

/**
 * Use a given value only within the scope of the handler. Not strictly necessary in many cases,
 * but helps to group lines of related functionality.
 */
export function use<Input, Output> (value: Input, handler: (input: Input) => Output) {
  return handler(value);
}

export function getObjectChanges<T> (oldObject: T, newObject: T) {
  const changedObject: Partial<T> = {};

  for (const key in newObject) {
    if (oldObject[key] !== newObject[key]) {
      changedObject[key] = newObject[key];
    }
  }

  return changedObject;
}
