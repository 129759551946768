import { Button, HSpacer, IconButton, Text, TopAppBar, VSpacer } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { CircularProgress, Container, darken, Divider, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatPoints, useDashboardNavigate } from './helpers';
import { useQuery } from 'react-query';
import { DashboardApi } from '@/utilities/api/DashboardApi';
import { QueryKeys } from '@/constants/QueryKeys';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Star from '@mui/icons-material/Star';
import { ChatBubbleOutline } from '@mui/icons-material';
import { useDashboard } from '@/hooks/useDashboard';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { formatPhoneNumber } from '@shared/utilities';
import { DashboardWallet, Salesperson } from '@/pages/CustomerDashboard/interfaces';
import { sortBy } from 'lodash';
import { getIcon } from '@/pages/Admin/FarmerList/helper';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { AppConfig } from '@/constants/AppConfig';

export const WalletPage = () => {
  const theme = useTheme();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;
  const navigateToPage = useDashboardNavigate();
  const [showSellerModal, setShowSellerModal] = useState(false);

  const { data: dashboardData } = useDashboard(accessToken);

  const { data: salespersons, isFetching: isSalespersonsFetching } = useQuery<Salesperson[]>(
    [QueryKeys.GET_DASHBOARD_SALESPERSONS, accessToken],
    async () => await DashboardApi.listRelevantSalespersons(accessToken!) as Salesperson[],
    {
      enabled: !!accessToken,
    },
  );

  if (!dashboardData) {
    return (
      <Container>
        <Stack alignItems='center'>
          <VSpacer size='14' />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  const {
    orders,
    retailer,
    wallets,
  } = dashboardData;

  const sortedWallets = sortBy(wallets, (wallet) => wallet.name);
  const retailerWallet = sortedWallets.find((wallet) => (
    wallet.retailerId && !wallet.manufacturerId
  ));
  if (retailerWallet) {
    sortedWallets.splice(sortedWallets.indexOf(retailerWallet), 1);
    sortedWallets.unshift(retailerWallet);
  }
  const lastPointsProcessedDate = orders.map((order) => (
    order.importHistory.pointsProcessedDate
  )).filter(Boolean).sort().pop();
  const formattedUpdateDate = lastPointsProcessedDate
    ? lastPointsProcessedDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    : undefined;

  const Header = (
    <TopAppBar
      isStatic
      leftAccessory={(
        <IconButton
          onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD, accessToken)}
          testID="back-button"
        >
          <ArrowBack />
        </IconButton>
      )}
      rightAccessory={<HSpacer size="7" />}
      sx={{ bgcolor: retailer.brandColor }}
      testID="top-app-bar"
    >
      <Text category="title-large" sx={{ color: 'white' }}>
        Wallet
      </Text>
    </TopAppBar>
  );

  return (
    <>
      <Stack
        bgcolor="white"
        color="black"
        flexGrow={1}
        height="auto"
        maxWidth="599px"
        minHeight="100vh"
        minWidth="360px"
      >
        {Header}
        <Stack bgcolor={darken(retailer.brandColor, 0.2)} color="white" p="16px 16px 20px">
          <Text fontSize="30px" fontWeight={500} lineHeight="36px">
            Place an order.<br />Redeem rewards.
          </Text>
          <VSpacer size="5" />
          <Stack direction="row" justifyContent="space-between">
            <Stack
              flex={1}
              justifyContent="space-between"
              sx={{ bgcolor: 'white', borderRadius: '4px', color: 'black', p: '8px 12px 12px' }}
            >
              <Text category="body-medium">
                Message your seller directly
              </Text>
              <VSpacer size="4" />
              <Stack alignItems="center">
                <Button
                  color="inherit"
                  disabled={isSalespersonsFetching}
                  onClick={() => setShowSellerModal(true)}
                  startIcon={<ChatBubbleOutline />}
                  sx={{ borderColor: theme.palette.grey[300] }}
                  testID="message-seller-button"
                  variant="outlined"
                >
                  Message
                </Button>
              </Stack>
            </Stack>
            <HSpacer size="5" />
            <Stack
              flex={1}
              sx={{ bgcolor: 'white', borderRadius: '4px', color: 'black', p: '8px 12px 12px' }}
            >
              <Text category="body-medium">
                Visit the {retailer.name} website
              </Text>
              <VSpacer size="4" />
              <Stack alignItems="center">
                <Button
                  color="inherit"
                  disabled={!retailer.websiteUrl}
                  endIcon={<ArrowForward />}
                  onClick={() => window.open(retailer.websiteUrl!, '_blank')}
                  sx={{ borderColor: theme.palette.grey[300] }}
                  testID="visit-retailer-website-button"
                  variant="outlined"
                >
                  Go
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <VSpacer size="4" />
        <Stack px="16px">
          <Text fontSize="13px">
            Points in your wallet can be used as a discount on any new order placed with
            {' '}
            {retailer.name}. 1 point = $1.00
          </Text>
          <VSpacer size="5" />
          {!!formattedUpdateDate && (
            <Stack alignItems="end">
              <Text category="body-xxsmall" sx={{ color: theme.palette.grey[700] }}>
                Updated {formattedUpdateDate}
              </Text>
            </Stack>
          )}
        </Stack>
        <VSpacer size="4" />
        <Divider light />
        <Stack divider={<Divider light />}>
          {sortedWallets.map((wallet) => (
            <WalletCard
              key={wallet.id}
              wallet={wallet}
            />
          ))}
        </Stack>
        <Divider light />
      </Stack>
      {showSellerModal && !!salespersons && (
        <SellerModal
          onClose={() => setShowSellerModal(false)}
          salespersons={salespersons}
        />
      )}
    </>
  );
};

interface WalletCardProps {
  wallet: DashboardWallet,
}

const WalletCard = ({
  wallet,
}: WalletCardProps) => (
  <Stack direction="row" p="16px">
    <Stack
      alignItems="center"
      borderRadius="4px"
      boxShadow="0px 1px 3px 1px #00000026"
      height="68px"
      justifyContent="center"
      p="8px"
      width="96px"
    >
      <img
        alt="wallet logo"
        src={wallet.logo
          ? `${AppConfig.staticImageHost}/${wallet.logo}`
          : getIcon(wallet.retailerId, wallet.manufacturerId)}
        style={{ height: '100%', objectFit: 'contain', width: '100%' }}
      />
    </Stack>
    <HSpacer size="5"/>
    <Stack>
      <Text category="title-small">
        {wallet.name}
      </Text>
      <Text category="body-medium">
        ${formatPoints(wallet.balance)} available
      </Text>
      <VSpacer size="2" />
      <Stack alignItems="center" direction="row">
        <Star sx={{ color: '#F1BC42', height: '15px', width: '15px' }}/>
        <HSpacer size="2"/>
        <Text category="label-medium" lineHeight="0px">
          {formatPoints(wallet.balance)} points
        </Text>
      </Stack>
    </Stack>
  </Stack>
);

interface SellerModalProps {
  onClose: () => void,
  salespersons: Salesperson[],
}

const SellerModal = ({
  onClose,
  salespersons,
}: SellerModalProps) => (
  <DashboardModal
    hideCloseButtonIcon
    onClose={onClose}
    open
    testID="seller-modal"
  >
    <Stack flexGrow={1} overflow="auto">
      <Stack alignItems="center">
        <Text category="title-large">
          Select a seller
        </Text>
      </Stack>
      <VSpacer size="5" />
      <Divider light />
      {salespersons.map((salesperson) => (
        <SellerCard
          key={salesperson.id}
          salesperson={salesperson}
        />
      ))}
      <Divider light />
    </Stack>
  </DashboardModal>
);

interface SellerCardProps {
  salesperson: Salesperson,
}

const SellerCard = ({
  salesperson,
}: SellerCardProps) => {
  const theme = useTheme();
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" p="16px">
      <Stack>
        {!!salesperson.userLocation && (
          <>
            <Text category="body-small" sx={{ color: theme.palette.grey[600] }}>
              {salesperson.userLocation.name}
            </Text>
            <VSpacer size="1" />
          </>
        )}
        <Text category="title-medium">
          {salesperson.businessName}
        </Text>
        {!!salesperson.telephone && (
          <Text category="body-medium" sx={{ color: theme.palette.grey[700] }}>
            {formatPhoneNumber(salesperson.telephone, 'paren')}
          </Text>
        )}
      </Stack>
      <HSpacer size="5" />
      <Button
        color="inherit"
        disabled={!salesperson.telephone}
        href={`sms:${salesperson.telephone}`}
        startIcon={<ChatBubbleOutline />}
        sx={{ borderColor: theme.palette.grey[300] }}
        testID={`message-seller-button-${salesperson.id}`}
        variant="outlined"
      >
        Message
      </Button>
    </Stack>
  );
};
